<template>
  <Layout>
    <div class="card" style="min-height: 80vh">
      <div class="card-header">
        <h3 class="text-uppercase mb-0">{{ $t("t_manager_position") }}</h3>
        <div class="text-end">
          <div class="btn-header">
            <button
              class="btn btn-soft-secondary btn-border me-2"
              @click="fn_showFormTimKiem()"
            >
              <i class="ri-search-line"></i>
              {{ $t("t-search") }}
            </button>
            <button
              class="btn btn-secondary btn-border"
              @click="fn_handle('create', null)"
              v-if="profile_user.roles === 'Admin'"
            >
              <i class="ri-add-circle-line"></i>
              {{ $t("t-add") }}
            </button>
          </div>
        </div>
      </div>
      <div class="card-body" style="padding: 0 0 15px 0">
        <div class="main-body">
          <div class="main-body-search mx-3 mb-3">
            <b-collapse
              v-model="tableRules.showFormSearch"
              id="myFormSearch"
              class="b-shadow"
            >
              <b-card>
                <el-form
                  label-position="left"
                  ref="ruleFormRef"
                  @submit.prevent
                  :rules="rulesForm"
                  :model="tableRules.dataSearch.value"
                >
                  <b-row>
                    <b-col md="6">
                      <el-form-item :label="`${$t('t_position_name')}`">
                        <el-input
                          clearable
                          v-model="tableRules.dataSearch.value['name']"
                          :placeholder="`${$t('t_place_position_name')}`"
                        ></el-input>
                      </el-form-item>
                    </b-col>
                    <b-col md="6">
                      <a
                        class="btn btn-soft-secondary btn-border waves-effect waves-light mx-3"
                        style="width: 80px"
                        @click="fn_showFormTimKiem()"
                      >
                        {{ $t("t-hide") }}
                      </a>
                      <button
                        class="btn btn-secondary bg-gradient btn-border waves-effect waves-light"
                        style="width: 100px"
                        native-type="submit"
                        @click="fn_TimKiem()"
                      >
                        {{ $t("t-search") }}
                      </button>
                    </b-col>
                  </b-row>
                </el-form>
              </b-card>
            </b-collapse>
          </div>
          <div class="mb-3" style="float: right">
            <el-pagination
              small
              v-model:currentPage="tableRules.page"
              v-model:pageSize="pageSizeDefault"
              :page-sizes="tableRules.lengthMenu"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="tableRules.total"
              @size-change="fn_tableSizeChange"
              @current-change="fn_tableCurentChange"
              @prev-click="fn_tablePrevClick"
              @next-click="fn_tableNextClick"
            />
          </div>
          <el-table
            size="small"
            :data="dataTables.value"
            style="width: 100%"
            max-height="550"
            v-loading="loading"
            @sort-change="fn_tableSortChange"
            :default-sort="{
              prop: tableRules.defaultSort[0],
              order: tableRules.defaultSort[1],
            }"
          >
            <el-table-column
              prop=""
              :label="`${$t('t-stt')}`"
              width="70"
              align="center"
            >
              <template #default="scope">
                <div class="text-center">
                  {{ tableRules.offset + scope.$index + 1 }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              :label="`${$t('t_position_name')}`"
              min-width="200"
              align="center"
              :sortable="true"
            />
            <el-table-column
              :label="`${$t('t-action')}`"
              align="center"
              width="150px"
            >
              <template #default="scope">
                <div class="btn-group-thao-tac">
                  <el-tooltip
                    class="box-item"
                    effect="light"
                    :content="`${$t('action_option')}`"
                    placement="left"
                  >
                    <button
                      type="button"
                      class="btn btn-soft-success waves-effect waves-light btn-sm"
                      @click="fn_handle('update', scope.row)"
                      :disabled="profile_user.roles === 'User'"
                    >
                      <i class="ri-edit-box-line"></i>
                    </button>
                  </el-tooltip>

                  <el-tooltip
                    class="box-item"
                    effect="light"
                    :content="`${$t('action_delete')}`"
                    placement="left"
                  >
                    <button
                      type="button"
                      class="btn btn-soft-danger waves-effect waves-light btn-sm"
                      @click="fn_handle('delete', scope.row)"
                      :disabled="profile_user.roles === 'User'"
                    >
                      <i class="ri-delete-bin-6-line"></i>
                    </button>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="mt-3" style="float: right">
            <el-pagination
              small
              v-model:currentPage="tableRules.page"
              v-model:pageSize="pageSizeDefault"
              :page-sizes="tableRules.lengthMenu"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="tableRules.total"
              @size-change="fn_tableSizeChange"
              @current-change="fn_tableCurentChange"
              @prev-click="fn_tablePrevClick"
              @next-click="fn_tableNextClick"
            />
          </div>
        </div>
      </div>
      <el-dialog
        v-model="showDialogAddPosition"
        :title="`${
          typeForm === 'createForm'
            ? $t('t_add_position')
            : $t('t_update_position')
        }`"
        width="500"
        align-center
        :close-on-click-modal="false"
        :before-close="closeDialog"
      >
        <div class="dialog-form">
          <p>{{ $t("t_position_name") }}</p>
          <el-input
            type="text"
            v-model="namePosition"
            clearable
            :placeholder="`${$t('t_place_position_name')}`"
            @change="namePosition = namePosition.trim()"
          ></el-input>
          <p class="dialog-form__error" v-show="notValidate">
            {{ $t("valid_required") }}
          </p>
          <div class="dialog-form__btns">
            <el-button type="primary" @click="actionPosition(typeForm)">
              {{ typeForm === "createForm" ? $t("t-add") : $t("t-update") }}
            </el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </Layout>
</template>

<script setup>
import Layout from "../../layouts/main.vue";
import { onMounted, reactive, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import MethodService from "../../service/MethodService";
import Swal from "sweetalert2";
import toastr from "toastr";
import DataForm from "./dataQuanLyChucDanh";
import mushroom from "cem-primary-api";
import { profileUserStore } from "../../stores/profileUser";
const profile_user = profileUserStore();
import { useI18n } from "vue-i18n";
const { t } = useI18n();
let loading = ref(true);
const router = useRouter();
const route = useRoute();
const tableRules = reactive(MethodService.copyObject(DataForm.tableRules));
const dataTables = reactive({ value: [] });
const pageSizeDefault = ref(20);
const showDialogAddPosition = ref(false);
const notValidate = ref(false);
const namePosition = ref("");
const typeForm = ref("createForm"); // updateForm or createForm
const idPosition = ref("");
const closeDialog = () => {
  notValidate.value = false;
  namePosition.value = "";
  showDialogAddPosition.value = false;
};
const fn_handle = (type, row) => {
  namePosition.value = "";
  if (type === "create") {
    typeForm.value = "createForm";
    showDialogAddPosition.value = true;
  }
  if (type === "update") {
    typeForm.value = "updateForm";
    idPosition.value = row.id;
    showDialogAddPosition.value = true;
    namePosition.value = row.name;
  }
  if (type === "delete") {
    Swal.fire({
      title: t("swal_noti"),
      text: t("t_noti_position"),
      icon: "warning",
      confirmButtonColor: "#f46a6a",
      confirmButtonText: t("swal_accept"),
      cancelButtonText: t("swal_cancel"),
      showCancelButton: true,
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await mushroom.position.deleteAsync({
            id: row.id,
          });
          if (response.result) {
            toastr.success(t("t_deleted_position"));
            getPosition();
          }
        } catch (e) {
          console.error("Có lỗi: %o", e);
          if (e.code === 500 && e.subCode === 2000) {
            toastr.warning(t("t_being_assigned_position"));
          } else MethodService.showError(e.code);
        }
      }
    });
  }
};
const fn_showFormTimKiem = () => {
  tableRules.showFormSearch = !tableRules.showFormSearch;
  router
    .replace(MethodService.routerReplace("DanhSachChucDanh", tableRules))
    .catch(() => {});
};
const fn_TimKiem = () => {
  if (tableRules.dataSearch.value["name"]) {
    tableRules.dataSearch.value["name"] =
      tableRules.dataSearch.value["name"].trim();
  }
  tableRules.filters = MethodService.filterTable(
    JSON.stringify(tableRules.dataSearch)
  );
  tableRules.offset = 0;
  tableRules.sort = "";
  getPosition();
};
const fn_tableSizeChange = (limit) => {
  tableRules.limit = limit;
  pageSizeDefault.value = limit;
  fn_tableChangeOffset(tableRules.page);
};
const fn_tableCurentChange = (page) => {
  fn_tableChangeOffset(page);
};
const fn_tablePrevClick = (page) => {
  fn_tableChangeOffset(page - 1);
};
const fn_tableNextClick = (page) => {
  fn_tableChangeOffset(page + 1);
};
const fn_tableChangeOffset = (page) => {
  tableRules.page = page;
  tableRules.offset = (tableRules.page - 1) * pageSizeDefault.value;
  getPosition();
};
const fn_tableSortChange = (column, tableSort) => {
  tableSort = tableRules;
  MethodService.tableSortChange(column, tableSort);
  getPosition();
};
const fn_hashUrl = (routeQuery, table_rules) => {
  routeQuery = route.query;
  table_rules = tableRules;
  MethodService.hashUrl(routeQuery, table_rules);
  pageSizeDefault.value = tableRules.limit;
};
const actionPosition = async (type) => {
  if (!namePosition.value) {
    notValidate.value = true;
    return;
  }

  notValidate.value = false;
  type === "createForm"
    ? await createPositionAsync()
    : await updatePositionAsync();

  showDialogAddPosition.value = false;
};
const createPositionAsync = async () => {
  try {
    const response = await mushroom.position.createAsync({
      name: namePosition.value,
    });
    if (response.result) {
      toastr.success(t("toastr_add_success"));
      await getPosition();
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
    MethodService.showError(e.code);
  }
};
const updatePositionAsync = async () => {
  try {
    const response = await mushroom.position.partialUpdateAsync({
      id: idPosition.value,
      name: namePosition.value,
    });
    if (response.result) {
      toastr.success(t("toastr_update_success"));
      await getPosition();
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
    MethodService.showError(e.code);
  }
};
const getPosition = async () => {
  let dataFilter = {
    filters: tableRules.filters,
    fields: tableRules.fields,
    limit: tableRules.limit,
    offset: tableRules.offset,
    sort: tableRules.sort,
  };
  router
    .replace(MethodService.routerReplace("DanhSachChucDanh", tableRules))
    .catch(() => {});
  try {
    const response = await mushroom.position.listAsync(dataFilter);
    if (response.result) {
      dataTables.value = response.result;
      tableRules.total = response.meta.total;
      tableRules.page = response.meta.offset / response.meta.limit + 1;
    }
  } catch (e) {
    MethodService.showError(e.code);
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  fn_hashUrl();
  await getPosition();
});
</script>
<style lang="scss" scoped>
.dialog-form {
  position: relative;

  &__error {
    color: var(--el-color-danger);
    position: absolute;
    font-size: 12px;
  }

  &__btns {
    margin-top: 25px;
    text-align: center;
  }
}
</style>
