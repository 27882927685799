let model = {};

// dữ liệu form
model.dataForm = {
  name: undefined,
};
model.tableRules = {
  lengthMenu: [10, 20, 50, 100],
  allowPaging: true,
  allowSorting: true,
  allowSelect: false,
  showFormSearch: false,
  showUrl: true,
  total: 0,
  page: 1,
  limit: 20,
  offset: 0,
  sort: "name",
  defaultSort: ["name", "ascending"],
  fields: "id,name,url",
  filters: "",
  dataSearch: {
    value: {
      name: "",
    },
    operator: {
      name: ":regex_i:",
    },
  },
};

export default model;
